<template>
    <div id="legal-notice" class="anchor-target">
        <section class="section bg-gray-alt mt-5">
            <div class="container">
                <h2>{{ $t('message.legal.legal_notice.title') }}</h2>
                <div v-html="$t('message.legal.legal_notice.content')"></div>

                <h2 id="cookies-policy" class="anchor-target">{{ $t('message.legal.cookies_policy.title') }}</h2>
                <div v-html="$t('message.legal.cookies_policy.content')"></div>

                <h2 id="privacy-policy" class="anchor-target"> {{ $t('message.legal.privacy_policy.title') }}</h2>
                <div v-html="$t('message.legal.privacy_policy.content')"></div>
            </div>
        </section>
    </div>
</template>

<script>
    export default {
        name: "Legal",
        metaInfo () {
            return {
                htmlAttrs: {
                    lang: this.$i18n.locale
                },
                title: this.$t('meta.legal.title'),
                link: [
                    { rel: 'canonical', href: 'https://www.syntelix.net' + this.$route.path.replace(/\/$/, "") }
                ],
                meta: [
                    {
                        vmid: 'description',
                        name: 'description',
                        content: this.$t('meta.legal.description')
                    },
                    {
                        vmid: 'og:title',
                        property: 'og:title',
                        content: this.$t('meta.legal.title')
                    },
                    {
                        vmid: 'og:description',
                        property: 'og:description',
                        content: this.$t('meta.legal.description')
                    },
                    {
                        vmid: 'og:type',
                        property: 'og:type',
                        content: 'website'
                    },
                    {
                        vmid: 'og:image',
                        property: 'og:image',
                        content: 'https://syntelix-website.s3-eu-west-1.amazonaws.com/og.jpg'
                    },
                    {
                        vmid: 'og:url',
                        property: 'og:url',
                        content: 'https://www.syntelix.net' + this.$route.path
                    }
                ]
            }
        }
    }
</script>

<style lang="scss" scoped>
    .anchor-target::before {
        content:"";
        display:block;
        height:80px; /* fixed header height*/
        margin:-80px 0 0; /* negative fixed header height */
    }
</style>
